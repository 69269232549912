import VueCookies from "vue-cookies"
import router from '@/routes'
import {logoutFromSaas} from "./service"

export const AuthStore = {

    state: {

        user: null,
        userToken: null,
        userLoggedIn: false,
    },

    mutations: {

        SET_USER(state, payload) {

          state.user = payload;

        },
        SET_LOGIN_STATE(state, payload) {
            // state.userObj = setupAuthDetails(payload.decodedToken);
            state.userLoggedIn = payload;
        },
        SET_USER_TOKEN(state, payload) {
            state.userToken = payload;
            VueCookies.set("token", payload);
        }
        // [SET_SERVER_RESPONSE](state, payload) {
        //     state.serverResponse = payload;
        // },
        // [SET_USER_ADMIN_STATUS](state, payload) {
        //     state.isUserAdmin = payload.isAdmin;
        // }

    },

    getters: {

        getUser: state =>  state.user,
        userToken: state => state.userToken,
        userLoggedIn: state => state.userLoggedIn
        

    },

    actions: {

        // [FETCH_USER_BY_ID]: ({ commit }, { id }) => {

        //   commit(SET_USER, { user: null });

        // }
        // setUserAdminStatus({commit}, payload) {
        //     commit('SET_USER_ADMIN_STATUS', payload);
        // },
        setLoginSession({ commit }, payload) {
            commit("SET_USER", payload.user)
            commit("SET_LOGIN_STATE", payload.userLoggedIn);
            VueCookies.set("token", payload.token);
            commit("SET_USER_TOKEN", payload.token)
            // window.open('/', "_self")
        },
        setNewUserToken({commit}, payload) {
            VueCookies.set("token", payload);
            commit("SET_USER_TOKEN", payload)
        },
        async logoutUser() {
            let getLogoutUri
            try {
                getLogoutUri = await logoutFromSaas();
            } catch (error) {
                if (error === "LogoutFailed") {
                    console.log('Logout!');
                }
            } finally {
                VueCookies.remove("token");
                VueCookies.remove("refresh_token");
                localStorage.removeItem('theme');
                localStorage.removeItem('themeObj');
                // VueCookies.remove("ACCOUNT_ID")
                const loginPath = router.resolve({ name: "Login" });
                // VueCookies.set("next-url", loginPath.href);
                // sessionStorage.removeItem("isTenant")
                // sessionStorage.removeItem("isClient")

                window.location.href = getLogoutUri || loginPath.href;
            }
        },
    }

};